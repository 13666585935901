.scrollup-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: none;
    color: #fff;
    width: 40px;
    height: 40px;
    border: #fff solid 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .scrollup-container:hover {
    background-color: #ffffff;
    color: #000000;
  }
  
  .scrollup-icon {
    font-size: 20px;
  }
  
  .scrollup-icon:before {
    color: white;
  }
  
  @keyframes arrowUp {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .scrollup-icon:before {
    animation: arrowUp 1s infinite;
  }
  



  
