.quote-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.quote-modal {
    background: #000000;
    padding: 2rem;
    width: 90%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    border: 3px solid transparent; 
    border-image: linear-gradient(103.22deg, #0062ff -13.86%, #af520b 99.55%);
    border-image-slice: 1;
}

.quote-modal-close {
    border: none;
    background: transparent;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
}

.quote-modal-logo {
    width: 120px;
    display: block;
    margin: 0 auto;
}

.quote-modal h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.5rem;
}

.quote-modal-radio-group {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.radio-label {
    margin: 0 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    color: #fff;
}

.radio-label input {
    margin-right: 0.5rem;
}

.radio-label.selected {
    color: #03446A;
    font-weight: bold;
}

.quote-modal input,
.quote-modal textarea {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.quote-modal textarea {
    height: 100px;
    resize: vertical;
}

.quote-modal button {
    width: 100%;
    padding: 0.75rem;
    background: none;
    color: white;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
    transition: background 0.3s;
}

.quote-modal button:hover {
    background: #022c5e;
}

.quote-modal-success {
    color: #28a745;
    text-align: center;
    margin-top: 1rem;
}
