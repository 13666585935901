@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #0062ff 1.84%, #af520b 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #0062ff -13.86%, #af520b 99.55%);
  
  --color-bg: #070707;
  --color-footer : #16181a;
  --color-blog: #3e4042;
  --color-text: #c6c6c6;
  --color-subtext: #FF8A71;
}
