.gpt3__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem; /* Add padding if needed */
}

.gpt3__brand div {
    flex: 1 1 150px; /* Flex-grow, flex-shrink, and flex-basis */
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__brand img {
    max-width: 100%; /* Ensure images are responsive */
    height: auto; /* Maintain aspect ratio */
}

@media screen and (max-width: 600px) {
    .gpt3__brand div {
        max-width: 120px;
        min-width: 100px;
        margin: 0.5rem;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__brand div {
        max-width: 100px;
        min-width: 80px;
        margin: 0.5rem;
    }
}
